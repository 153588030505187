import React, { useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";
import VoiceClone from "./VoiceClone";

const HomeScreeen = () => {
    const [tab, setTab] = useState('voiceclone');

    return (
        <>
            <div id="header">
                {/* <ProSidebar >
                    <SidebarHeader>
                        <div className="logotext">
                            <p>{"dubstudio.in"}</p>
                        </div>

                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="square">
                            <MenuItem
                                component={Link}
                                active={tab == 'subtitle'} to='/test'
                                onClick={() => handleTabClick('subtitle')}
                            >
                                Subtitle Generator
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                active={tab == 'voiceclone'} to='/test'
                                onClick={() => handleTabClick('voiceclone')}
                            >
                                Voice Cloning
                            </MenuItem>
                        </Menu>
                    </SidebarContent>
                </ProSidebar> */}
            </div>


            {/* {tab === 'subtitle' && <SubTitleGenerator />} */}
            {tab === 'voiceclone' && <VoiceClone />}
        </>
    );
};

export default HomeScreeen;
