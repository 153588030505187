import React from 'react';
import { Button, Box } from "@mui/material";
import './Header.css';

const DownloadLink = ({ presignedUrl, fileName }) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = fileName;
        link.click();
    };

    return (
        <div className='style-download'>
            {/* <button onClick={handleDownload}>Download Voice Clone File</button> */}
            <Button component="label" variant="contained" style={{ width: 300 }} onClick={handleDownload}>
                Download Audio
            </Button>
        </div>
    );
};

export default DownloadLink;
