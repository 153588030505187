import React from 'react';

const NavBar = () => {
    return (

        <div className="logotext">
            <p>{"dubstudio.in"}</p>
        </div>
    );
}

export default NavBar;