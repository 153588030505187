import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const PollingComponent = (props) => {
  const [pollingData, setPollingData] = useState(null);
  const timerIdRef = useRef(null);

  const {
    setFinalValue
  } = props;

  const {
    setTranscriptDetails
  } = props;

  const {
    setIsLoading
  } = props;

  useEffect(() => {
    fetchPollingData();

    timerIdRef.current = setInterval(fetchPollingData, 5000);

    return () => {
      stopPolling();
    };
  }, []);

  const fetchPollingData = async () => {
    console.log("macha came here...");
    debugger;
    const payload = {
      "object_id": props.objectKey
    };

    try {

      var response = null;

      if (props.voiceClone) {
        response = await axios.post('https://api.dubstudio.in/api/v1/get-file', payload);
      } else {
        response = await axios.post('https://api.dubstudio.in/api/v1/get-subs', payload);
      }

      console.log(payload);

      debugger;
      if (response.status === 200) {
        const data = response.data;
        const status = data.status;

        if (status === 'complete') {
          setPollingData(data);
          // props.onValueChange(data);
          // console.log

          if (props.voiceClone) {
            setFinalValue(data.presigned_url);
            setIsLoading(false);
            stopPolling();
            return;
          }

          setFinalValue(data.text);
          setTranscriptDetails({
            text: data.text,
            text_with_timestamps: data.text_with_timestamps
          });
          setIsLoading(false);
          stopPolling();
        }
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  const stopPolling = () => {
    clearInterval(timerIdRef.current);
  };


  return (
    <div>
      {pollingData && (
        <div>

        </div>
      )}
    </div>
  );
};

export default PollingComponent;
