import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Box } from "@mui/material";
import "./Header.css";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import PollingComponent from './PollingComponent';
import DownloadLink from './DownloadLink';
import NavBar from './NavBar';

function VoiceClone() {

    const [voices] = useState([
        { code: 'nitesh-medium', title: 'Nitesh' },
        { code: 'kcr-big', title: 'KCR' },
        { code: 'jagan-big', title: 'YS Jagan' },
        { code: 'cbn-big', title: 'Chandra Babu' },
    ]);
    const [toggleContents, setToggleContents] = useState("Select a Voice");
    const [finalValue, setFinalValue] = useState('');
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [data, setData] = useState(null);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [outputObjectId, setOutputObjectId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [startPolling, setStartPolling] = useState(false);
    const maxMemoryLimitInBytes = 5242880;

    const handleOnChange = e => {
        const selectedFile = e.target.files[0];
        const { name } = e.target.files[0];

        debugger;
        const allowedFormats = ['mp3', 'wav']; // Define the allowed file formats
        const fileExtension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);

        if (!allowedFormats.includes(fileExtension.toLowerCase())) {
            alert('Invalid file format. Please select a file of format .wav or .mp3 only');
            e.target.value = '';
            return;
        }

        if (selectedFile.size > maxMemoryLimitInBytes) {
            alert('File size exceeds the maximum limit of 5MB , Upload a shorter one.');
            e.target.value = '';
            return;
        }

        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setFileName(name);
    };

    const uploadToS3 = async (payload) => {
        console.log("[API][S3 Upload] Trying to upload file to S3");
        try {
            const response = await axios.post('https://api.dubstudio.in/api/v1/get-presigned-url', payload);

            // debugger;
            console.log("bro the response is " + response.data);
            setData(response.data);

            console.log("[API][S3 Upload] response received after api ", data);
            if (response.status === 200) {
                console.log("[API][S3 Upload] File upload successful with file path ", response.data);
                return response.data;
            }
            else {
                console.log("[API][S3 Upload] Error while uploading file");
                alert("error");
                return;
            }
        } catch (err) {
            alert(err);
            return;
        }
    };

    const putFileToS3 = async (s3FilePath) => {
        debugger;
        try {
            const response = await axios.put(s3FilePath, file, {
                headers: {
                    'Content-Type': file.type,
                }
            });

            return response;
        } catch (error) {
            console.error('Error: {}', error);
            return "";
        }

    };

    const getVoiceClone = async (objectKey) => {

        console.log("arey macha where are you....");
        setOutputObjectId(objectKey);

        const payload = {
            "object_id": objectKey,
            "model_name": selectedVoice
        };
        const response = await axios.post('https://api.dubstudio.in/api/v1/change-voice', payload);

        if (response.status === 200) {
            const data = response.data;
            setStartPolling(true);
        }
    };

    const cloneAnother = async e => {
        e.preventDefault();
        window.location.href = '/'
    };

    const handleSubmit = async e => {
        e.preventDefault();

        debugger;
        if (selectedVoice === null) {
            alert('Please select a voice before submitting the form.');
            return;
        }

        if (fileName === '') {
            alert('Please select a file.');
            return;
        }



        setIsLoading(true);

        const payload = {
            "file_name": fileName,
        };

        const data = await uploadToS3(payload);
        var objectKey = "";
        var s3FilePath = "";
        var response = "";

        if (data) {
            objectKey = data.object_id;
            s3FilePath = data.presigned_url;
            response = await putFileToS3(s3FilePath);
        } else {
            return "UPLOAD_AGAIN";
        }

        debugger;

        if (response.status === 200) {
            console.log('File uploaded successfully!');
            getVoiceClone(objectKey);
        } else {
            console.error('Error uploading file.');
        }

        console.log(payload);
    };

    return (
        <>
            <NavBar />
            <div id="direction">
                {
                    finalValue == ''
                    &&
                    <div className='form-style'>
                        <form>
                            <h1 id="text-here" className='text-here'>AI Voice Generator</h1>

                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                <ol style={{ listStyleType: 'disc' }}>
                                    <li>Select your preferred AI voice.</li>
                                    <li> Upload the audio file in wav/mp3 format.</li>
                                    <li>Click on Submit & Your audio will be converted to preferred voice.</li>
                                    <li>For feedback please mail to : <strong> dubstudiofeedback@gmail.com (This will help us a looooot....)</strong></li>
                                    <li>See the <strong>MAGIC</strong> as it unfolds and don't hit refresh. </li>
                                </ol>
                            </Alert>

                            <div className="container">
                                <div className="upload-button-voice">
                                    <Dropdown
                                        onSelect={eventKey => {
                                            const { code, title } = voices.find(({ code }) => eventKey === code);

                                            setSelectedVoice(eventKey);
                                            setToggleContents(<> {title}</>);
                                        }}
                                    >
                                        <Dropdown.Toggle variant="primary" id="dropdown-flags" className="text-left">
                                            {toggleContents}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {voices.map(({ code, title }) => (
                                                <Dropdown.Item key={code} eventKey={code}> {title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="upload-button-voice">
                                    <Button className="choose-file-btn" component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                        Choose file
                                        <input type="file" hidden onChange={handleOnChange} />
                                    </Button>
                                    <Box id="file-text" display="inline-block">{fileName}</Box>
                                </div>

                            </div>

                            <div className="upload-button-voice submit-button">
                                <Button component="label" variant="contained" style={{ width: 150 }} onClick={handleSubmit}>
                                    Upload File
                                </Button>
                            </div>


                        </form>
                        <div>
                            {isLoading ? (

                                <Box sx={{ width: '80%', paddingLeft: '2pc', paddingTop: '1pc' }}>
                                    <LinearProgress />
                                </Box>
                            ) : null
                            }
                        </div>
                    </div>


                }

                {startPolling ?
                    (<PollingComponent
                        objectKey={outputObjectId}
                        voiceClone={true}
                        setFinalValue={setFinalValue}
                        setIsLoading={setIsLoading} />) : null
                }
                {
                    !!finalValue
                    &&
                    <div>
                        <div className='new-page-sub'>
                            <div>
                                <DownloadLink presignedUrl={finalValue} fileName={fileName} />
                            </div>

                            <div className='clone-button'>
                                <Button component="label" variant="contained" style={{ width: 300 }} onClick={cloneAnother}>
                                    Try Another Voice.
                                </Button>
                            </div>
                        </div>


                    </ div>
                }
            </div>
        </>

    );
}

export default VoiceClone;